import {graphql, navigate} from 'gatsby'
import {useEffect, useState} from 'react'
import {toast, ToastContainer} from 'react-toastify'

import ArticleContent from 'src/components/ArticleContent'
import {Box, Grid} from 'src/components/Box'
import CourseHeader from 'src/components/Course/CourseHeader'
import CourseStepList from 'src/components/Course/CourseList'
import Layout from 'src/components/Layout'
import LoginModal from 'src/components/LoginModal'
import useViewer from 'src/hooks/useViewer'
import {FlairProvider} from 'src/context/FlairContext'
import VideoType from 'src/components/Course/VideoType'
import ShortAnswer from 'src/components/Course/ShortAnswer'
import useCourseCompletedInteraction from 'src/hooks/useCourseCompletedInteraction'
import {useConfettiCannon} from 'src/context/ConfettiContext'
import useCreateAsaTaskStep from 'src/hooks/useCreateAsaTaskStep'
import 'react-toastify/dist/ReactToastify.css'
import {parseErrorString} from 'src/lib/util'
import useCourseDatapoint from 'src/hooks/useCourseDatapoint'

export const query = graphql`
  query CourseStepPageData($id: String) {
    contentfulTasks(contentful_id: {eq: $id}) {
      ...CourseFragment
    }
  }
`

const CourseStepPageContext = ({data, pageContext, location}: any) => {
  const steps = data.contentfulTasks.steps.map((step: any) => ({
    ...step,
    description: step.description?.description || '',
  }))

  const queryParams = new URLSearchParams(location.search)
  const userId = parseInt(queryParams.get('user_id') || '')
  const uniqueTaskKey = queryParams.get('unique_task_key') || ''
  const stepNumber = parseInt(queryParams.get('step_number') || '')
  const description = data.contentfulTasks.description?.description || ''

  const {EVOLVE_ME_FRONTEND_URL} = pageContext

  const {contentful_id, title, courseLink, featuredImageUrl} =
    data.contentfulTasks
  const {viewer} = useViewer()
  const targetId = pageContext.stepId
  let found = false
  const [inProgress, setInProgress] = useState(false)
  const {fireConfetti} = useConfettiCannon()
  const createAsaTaskStep = useCreateAsaTaskStep()
  const trackCourseDatapoint = useCourseDatapoint()

  const loginStep = {
    title: steps[0].title,
    description: steps[0].description,
    imageUrl: steps[0].imageUrl,
    averageDuration: 3,
    asaPoints: 1000,
    type: 'Account',
    onCompletion: () => {
      createAsaTaskStep({
        variables: {
          stepNumber: stepNumber,
          userId: userId,
          uniqueTaskKey: uniqueTaskKey,
        },
      })
        .then((_response) => {
          fireConfetti(true)
          queryParams.set('step_number', `${stepNumber + 1}`)
          toast.success('Task step completed!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
          trackCourseDatapoint(contentful_id, title, 'start', 'evolveme')
          navigate(`${steps[1].stepLink}?${queryParams.toString()}`)
        })
        .catch((error) => {
          const errorMessage = parseErrorString(error.message)
          if (errorMessage?.errorCode.includes('EM2')) {
            toast.error(errorMessage?.errorMessage, {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          }
        })
    },
    onClick: () => showLoginModal(-1),
    ...steps[0],
  }

  steps[0] = loginStep
  let currentStep: any
  const restSteps: any = []

  const completedSteps = steps
    .map((obj: any, index: number) => {
      if (!found) {
        if (obj.contentful_id !== targetId) {
          return {status: 'completed', ...obj}
        } else {
          found = true
          currentStep = {
            onClick: () => {
              if (viewer === 'not-logged-in') {
                showLoginModal(-1)
              } else if (viewer) {
                queryParams.set('step_number', `${stepNumber + 1}`)
                if (index + 1 < steps.length) {
                  navigate(
                    `${steps[index + 1]?.stepLink}?${queryParams.toString()}`
                  )
                } else {
                  navigate(`${EVOLVE_ME_FRONTEND_URL}/dashboard`)
                }
              }
            },
            status: 'inprogress-disabled',
            onCompletion: () => {
              createAsaTaskStep({
                variables: {
                  stepNumber: stepNumber,
                  userId: userId,
                  uniqueTaskKey: uniqueTaskKey,
                },
              })
                .then((_response) => {
                  setInProgress(true)
                  fireConfetti(true)
                  queryParams.set('step_number', `${stepNumber + 1}`)
                  toast.success('Task step completed!', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  })
                  if (index + 1 >= steps.length) {
                    navigate(`${EVOLVE_ME_FRONTEND_URL}/dashboard`)
                  }
                })
                .catch((error) => {
                  const errorMessage = parseErrorString(error.message)
                  if (errorMessage?.errorCode.includes('EM2')) {
                    toast.error(errorMessage?.errorMessage, {
                      position: 'top-center',
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: 'light',
                    })
                  }
                })
              if (index + 1 >= steps.length) {
                navigate(`${EVOLVE_ME_FRONTEND_URL}/dashboard`)
              }
            },
            ...obj,
          }
          return null
        }
      } else {
        restSteps.push({status: 'disabled', ...obj})
        return null
      }
    })
    .filter((obj: any) => obj !== null)

  const newSteps = [...completedSteps, currentStep, ...restSteps]
  useEffect(() => {
    if (
      (!viewer || viewer === 'not-logged-in') &&
      currentStep.contentful_id === steps[0].contentful_id
    ) {
      setInProgress(true)
    }
  }, [viewer, currentStep, steps])

  useEffect(() => {
    if (
      currentStep.content?.__typename === 'ContentfulArticleTile' ||
      currentStep.content?.__typename === 'ContentfulVideoContent' ||
      currentStep.content?.__typename === 'ContentfulSelfReflectionQuestions'
    ) {
      if (viewer === 'not-logged-in') {
        showLoginModal(-1)
      } else if (viewer && inProgress) {
        currentStep.onCompletion()
        createInteraction({
          variables: {
            taskId: contentful_id,
            stepId: currentStep.contentful_id,
          },
        })
      }
    }
  }, [inProgress, viewer])

  const [showModal, setShowModal] = useState(false)
  const [modalCss, setModalCss] = useState({})
  const createInteraction = useCourseCompletedInteraction()

  function removeLoginModal() {
    setModalCss({})
    setShowModal(false)
  }

  function showLoginModal(_index: number) {
    setModalCss({
      pointerEvents: 'none',
      opacity: 0.3,
    })
    setShowModal(true)
  }

  useEffect(() => {
    setTimeout(() => {
      if (stepNumber !== 1 && viewer === 'not-logged-in') {
        showLoginModal(-1)
      } else if (
        viewer &&
        viewer !== 'not-logged-in' &&
        currentStep.contentful_id === steps[0].contentful_id
      ) {
        currentStep.onCompletion()
        removeLoginModal()
      } else {
        removeLoginModal()
      }
    }, 1000)
  }, [viewer, stepNumber])

  if (!userId || !uniqueTaskKey || !stepNumber) {
    if (typeof window !== 'undefined') {
      navigate(courseLink)
    }
    return
  }

  return (
    <Layout>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <LoginModal
        showModal={showModal}
        closeModal={removeLoginModal}
        locationPathname={location.pathname + location.search || ''}
        fromModal="courses-from-evolve-me"
      >
        Unlock ALL
        <br />
        Get Schooled Courses!
      </LoginModal>
      <Box css={{position: 'relative', ...modalCss}}>
        {currentStep.content?.__typename === 'ContentfulArticleTile' ? (
          <FlairProvider seed={parseInt(currentStep.content.id, 16)}>
            <Grid
              gridTemplateColumns={{lg: '1fr'}}
              gridTemplateRows={{lg: 'auto auto'}}
              gridColumnGap="5"
            >
              <Grid gridGap="5">
                <ArticleContent
                  tile={currentStep.content}
                  setInProgress={setInProgress}
                />
              </Grid>
            </Grid>
          </FlairProvider>
        ) : currentStep.content?.__typename === 'ContentfulVideoContent' ? (
          <FlairProvider seed={parseInt(currentStep.content.id, 16)}>
            <VideoType
              mainTitle={title}
              title={currentStep.content?.title}
              videoLink={currentStep.content?.videoLink}
              videoDescription={
                currentStep.content?.description?.description || ''
              }
              setShowButton={setInProgress}
            />
          </FlairProvider>
        ) : currentStep.content?.__typename ===
          'ContentfulSelfReflectionQuestions' ? (
          <ShortAnswer
            question={currentStep.content?.question.question}
            description={currentStep.content?.description?.description || ''}
            contentfulId={contentful_id}
            stepId={currentStep.contentful_id}
            setInProgress={setInProgress}
            redirectUrl={queryParams.get('returnTo')}
            onCompletion={() =>
              trackCourseDatapoint(contentful_id, title, 'end', 'evolveme')
            }
          />
        ) : (
          <CourseHeader
            title={title}
            description={description}
            featuredImageUrl={featuredImageUrl}
            stepCount={newSteps.length}
          />
        )}
        <CourseStepList
          contentTypeName={currentStep.content?.__typename}
          showButton={inProgress}
          title="Course Playlist"
          steps={newSteps}
        />
      </Box>
    </Layout>
  )
}

export default CourseStepPageContext
